<template>
  <InfoModal
    title="Information om överlåtelser"
    class="information-modal"
    mobile-full-width
    :is-disabled="!hasReadInfo"
    :show-close-btn="hasReadInfo"
    @click="$emit('closeInfoModal')"
  >
    <div class="information-wrapper">
      <template v-if="isPrivateFlow">
        <div class="information-container">
          <h2>Information om Överlåtelser</h2>
          <p>
            För att få överlåta en lägenhet krävs samtycke från hyresvärden.
          </p>
          <br />
          <p>
            Enligt 12:34 jordabalken har en hyresgäst, som inte tänker bo i sin
            bostadslägenhet, rätt att överlåta hyresavtalet till en närstående
            som varaktigt sammanbott med honom/henne. Med varaktigt menas att
            sammanboendet pågått under de tre senaste åren.
          </p>
        </div>
        <div class="information-container">
          <p>
            Förutsättningen för att överlåtelsen ska godkännas är att lägenheten
            har använts som gemensamt hushåll och varit den enda stadigvarande
            bostaden för nuvarande hyresgäst och föreslagen hyresgäst. Nuvarande
            hyresgäst och föreslagen hyresgäst måste vara närstående med
            varandra vilket innebär att en vän, inneboende eller
            andrahandshyresgäst inte kan överta ett hyresavtal.
            <br /><br />
            För att godkännas som ny hyresgäst så måste man uppfylla
            hyresvärdens kriterier, så som exempelvis ekonomisk förmåga att
            betala hyran och att inga störningar eller andra problem har
            förekommit under boendetiden. Nuvarande hyresgäst ska också kunna
            styrka sitt nya boende.
          </p>
          <br />
          <p>
            Överlåtelse av hyresavtal kan också bli aktuellt vid dödsfall.
            Överlåtelsen kan då ske under samma förutsättningar som ovan, men
            det är dödsboet som ansöker om överlåtelsen.
          </p>
        </div>
        <div class="information-container">
          <h2>Befintligt skick</h2>
          <p>
            Om hyresvärden lämnar sitt samtycke till överlåtelsen träder den
            föreslagna hyresgästen in i ett redan pågående avtalsförhållande och
            denne övertar därmed den föregående hyresgästens skyldigheter.
          </p>
          <br />
          <b>Det innebär att den tillträdande hyresgästen:</b>
          <ul>
            <li>Övertar lägenheten i befintligt skick</li>
            <li>
              Ansvarar för eventuella skador, installationer, ändringar och
              överslitage
            </li>
            <li>
              Ansvarar för eventuellt obetalda skulder som hänför sig till tiden
              för överlåtelsen
            </li>
          </ul>
          Besiktning av lägenheten genomförs inte före överlåtelsen.
        </div>
        <div class="information-container">
          <h2>Handläggningstid</h2>
          <p>
            Tiden för handläggning av ansökan kan komma att bli upp till 2
            månader då hyresvärden handlägger ansökningar allt efter de kommer
            in.
          </p>
        </div>
        <div class="information-container">
          <h2>Om hyresvärden inte lämnar samtycke</h2>
          <p>
            Om överlåtelse genomförs utan samtycke från hyresvärden kan du som
            hyresgäst förlora din hyresrätt. Detsamma gäller om samtycke eller
            tillstånd grundats på oriktiga uppgifter.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="information-container">
          <h2>Information om Överlåtelser</h2>
          <p>För att få överlåta lägenhet krävs samtycke från hyresvärden.</p>
          <br />
          <strong>Text saknas för företag</strong>
        </div>
      </template>
    </div>
    <Checkbox
      v-if="!hasReadInfo"
      v-model="checkedReadInfo"
      name="checkedReadInfo"
      description="Jag har läst och tagit del av informationen"
      :checked="checkedReadInfo"
      @change="checkedReadInfoToggle"
    />
    <BaseButton
      v-if="!hasReadInfo"
      :is-loading="false"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-small="isMobile"
      type="button"
      :is-disabled="!checkedReadInfo"
      @click="$emit('confirmAndCloseInfoModal')"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Godkänn och stäng
      </BaseIconText>
    </BaseButton>
    <BaseButton
      v-else
      :is-loading="false"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-small="isMobile"
      type="button"
      @click="$emit('closeInfoModal')"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Stäng
      </BaseIconText>
    </BaseButton>
  </InfoModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InfoModal from '@/components/InfoModal.vue';
import Checkbox from '@/components/Checkbox.vue';
import BaseButton from './BaseButton.vue';
import BaseIconText from './BaseIconText.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'SubletInformationModal',
  components: {
    InfoModal,
    Checkbox,
    BaseButton,
    BaseIconText
  },
  props: {
    hasReadInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedReadInfo: false,
      svgs
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    ...mapGetters({
      isMobile: 'app/isMobile',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    })
  },
  methods: {
    checkedReadInfoToggle() {
      this.checkedReadInfo = !this.checkedReadInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
.information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100%;
  padding: 1rem 0;

  .information-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;

    h2,
    p {
      max-width: 100%;
      overflow-wrap: break-word;
    }

    ul {
      margin: 2rem 0 0 0;
      padding: 0 0 0 2rem;
    }
  }
}
</style>
